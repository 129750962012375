<template>
  <div class="text-center">
    <b-form-input
      ref="code1"
      v-model="input[1]"
      class="code-auth mr-1 mb-2"
      :class="{ novalid: isValid }"
      :formatter="formatter"
      @keyup="onKeyUp(1, $event)"
      @focus="onFocus(1)"
      @keydown="onKeyDown(1)"
      @input="onInput(1, $event)"
      @click="onClick"
    />
    <b-form-input
      ref="code2"
      v-model="input[2]"
      class="code-auth mr-1 mb-2"
      :class="{ novalid: isValid }"
      :formatter="formatter"
      @keyup="onKeyUp(2, $event)"
      @focus="onFocus(2)"
      @keydown="onKeyDown(2)"
      @input="onInput(2, $event)"
      @click="onClick"
    />
    <b-form-input
      ref="code3"
      v-model="input[3]"
      class="code-auth mr-1 mb-2"
      :class="{ novalid: isValid }"
      :formatter="formatter"
      @keyup="onKeyUp(3, $event)"
      @focus="onFocus(3)"
      @keydown="onKeyDown(3)"
      @input="onInput(3, $event)"
      @click="onClick"
    />
    <b-form-input
      ref="code4"
      v-model="input[4]"
      class="code-auth mr-1 mb-2"
      :class="{ novalid: isValid }"
      :formatter="formatter"
      @keyup="onKeyUp(4, $event)"
      @focus="onFocus(4)"
      @keydown="onKeyDown(4)"
      @input="onInput(4, $event)"
      @click="onClick"
    />

    <b-button
      class="button-confirm mb-2"
      variant="primary"
      block
      @click="sendPassword"
    >
      Подтвердить
    </b-button>

    <b-card-text class="text-center mt-2">
      <div>Запросить код еще раз? </div>
      <div>
        <feather-icon
          v-if="currentTime !== 0"
          icon="ClockIcon"
          size="16"
        />
        <span
          v-if="currentTime !== 0"
          style="width: 45px; margin-left: 5px;"
        > {{ currentTime }} сек</span>
        <b-link
          v-if="currentTime === 0"
          @click="reSendCode"
        >
          <span>отправить код</span>
        </b-link>
      </div>
    </b-card-text>
  </div>
</template>

<script>
import {
  BFormInput, BCardText, BButton, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardText,
    BButton,
    BLink,
  },
  props: {
    limitStartTime: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      input: {
        1: null,
        2: null,
        3: null,
        4: null,
      },
      isReSendCode: false,
      currentTime: 0,
      timer: null,
      isValid: false,
    }
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
        this.isReSendCode = true
      }
    },
  },
  destroyed() {
    this.stopTimer()
  },
  mounted() {
    this.currentTime = this.limitStartTime
    this.startTimer()
  },
  methods: {
    formatter(value) {
      return value.replace(/\D/g, '')
    },
    async sendPassword() {
      let error = false
      Object.keys(this.input).forEach(x => {
        if (!this.input[x]) {
          error = true
        }
      })
      if (error) {
        this.isValid = error
        return
      }
      this.$emit('send', Object.values(this.input).map(i => Object.values(i)).join(''))
    },
    onClick(event) {
      this.isValid = false
      event.target.select()
    },
    onInput(index, event) {
      if (event && String(event).length > 1) {
        const data = String(event.replace(/\D/g, '')).split('') || []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 4; i++) {
          this.input[i + 1] = data[i] || null
          this.$refs[`code${i + 1}`].blur()
        }
        this.input[index] = String(this.input[index].replace(/\D/g, '')) || null
      }
    },
    onKeyDown(index) {
      if (this.input[index] && this.input[index].length === 1) {
        this.input[index] = null
      }
      // eslint-disable-next-line no-plusplus
      for (let i = index; i < 5; i++) {
        this.input[i] = null
      }
    },
    onKeyUp(index, event) {
      const eventCode = event.keyCode
      this.input[index] = event.target.value
      if (this.input[index] && this.input[index].length === 1) {
        if (index !== 4) {
          this.$refs[`code${index + 1}`].focus()
        } else {
          this.$refs[`code${index}`].blur()
          // Submit code
        }
      }
      if (eventCode === 8) {
        if (index !== 4) {
          // eslint-disable-next-line no-plusplus
          for (let i = index; i < 5; i++) {
            this.input[i] = null
          }
        }
        if (index !== 1) {
          this.$refs[`code${index - 1}`].focus()
        }
      }
      if (eventCode === 46) {
        if (index !== 4) {
          // eslint-disable-next-line no-plusplus
          for (let i = index; i < 5; i++) {
            this.input[i] = null
          }
        }
      }
    },
    onFocus(index) {
      // eslint-disable-next-line no-plusplus
      for (let item = 1; item < index; item++) {
        const currentElement = this.$refs[`code${item}`]
        if (!currentElement.value) {
          currentElement.focus()
          break
        }
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        // eslint-disable-next-line no-plusplus
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    reSendCode() {
      this.currentTime = this.limitStartTime
      this.$emit('resendcode')
      this.startTimer()
    },
  },
}
</script>

<style lang="scss">
  .code-auth {
    width: 45px;
    display: inline-block;
    // padding: 0.4rem 0.25rem;
    text-align: center;
  }
  .code-auth input::-webkit-inner-spin-button,
  .code-auth input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
//   .button-confirm {
//     // width: 120px;
//     // display: inline-block;
//     // padding: 0.8rem 0.3rem;
//     // margin-top: -5px;
// }
.code-auth.novalid {
  border-color: #ea5455;
}
</style>
